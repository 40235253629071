<template>
    <div v-if="enabled">
        <i 
            v-for="(starIndex) in 5" 
            :key="starIndex"
            :class="[getStarClass(starIndex, rating), starClass]"
        />
    </div>
</template>

<script>
export default {
    props: {
        starClass: {
            type: String,
            required: false,
            default: 'fa-xl'
        },
        rating: {
            type: Number,
            required: true
        },
        showInNorway: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        enabled() {
            return ! this.$isNorway() || this.showInNorway;
        }
    },
    methods: {
        getStarClass(starIndex, score) {
            const roundedScore = Math.round(score * 2) / 2;
            const halfStarIndex = Math.floor(roundedScore) + 1;
            const hasHalfStar = roundedScore % 1 !== 0;

            if (starIndex <= roundedScore) {
                return 'fa-solid fa-star';
            }

            if (starIndex === halfStarIndex && hasHalfStar) {
                return 'fa-solid fa-star-half-stroke';
            }

            return 'fa-regular fa-star';
        }
    }
};
</script>
<style lang="scss" scoped>
    div {
        width: 100%;

        i {
            color: $pink;
        }
    }
</style>
